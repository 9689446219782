import { createTheme } from "@mui/material";

const themeShadows = createTheme();

export const theme = createTheme({
  typography: {
    fontFamily: '"Poppins", sans-serif',
    resumeHeader: {
      color: "#7964b5",
      marginBottom: "16px",
      fontSize: ".85rem",
      fontWeight: 500,
    },
    resumeHeaderSub: {
      color: "#7964b5",
      marginBottom: "16px",
      fontSize: ".8rem",
      fontWeight: 500,
      marginTop: "-5px",
    },
    resumeHeaderError: {
      color: "#d32f2f",
      marginLeft: "3px",
      fontSize: ".8rem",
      fontStyle: "italic",
      fontWeight: 400,
    },
    inputLabel: {
      lineHeight: 1.5,
      color: "#7964b5",
      marginBottom: "8px",
      fontSize: ".8rem",
      fontWeight: 400,
      display: "inline-block",
    },
    accordionText: {
      color: "#7964b5",
      fontSize: ".9rem",
      fontWeight: 600,
    },
    clientProfileHeader: {
      color: "#7964b5",
      fontWeight: 600,
      fontSize: ".9rem",
      marginBottom: "16px",
      display: "inline-block",
    },
    clientDocTexIcon: {
      color: "#7964b5",
      fontWeight: 500,
      fontSize: ".75rem",
      textAlign: "center",
    },
    errorLabel: {
      fontSize: ".7rem",
      fontWeight: "400",
      textAlign: "left",
      marginTop: "5px",
      color: "#d32f2f",
    },
  },
  palette: {
    brandColor: {
      100: "#191429",
      200: "#281f42",
      300: "#372b5a",
      400: "#463672",
      500: "#55428a",
      600: "#7964b5",
      700: "#a698cd",
      800: "#d2cbe6",
      900: "#f0eef7",
      1000: "#836CA0",
      1100: "#624C86",
      1200: "#532FB8",
      1300: "#7942af",
      1400: "#cebbfc",
      1500: "#faf9fe",
      1600: "#6751a6",
      1700: "#E6E0F4",
    },
    blue: {
      100: "#063e60",
      200: "#074e78",
      300: "#085d91",
      400: "#0b7cc1",
      500: "#109cf1",
      600: "#56b9f5",
      700: "#87cdf8",
      800: "#b7e1fb",
      900: "#E4F1F9",
      1000: "#0052B4",
    },
    red: {
      100: "#7e1006",
      200: "#af1609",
      300: "#e01d0b",
      400: "#f53a29",
      500: "#f7685b",
      600: "#f9948a",
      700: "#fcc0bb",
      800: "#fde0dd",
      900: "#fef2f0",
      1000: "#350e15ad",
      1100: "#fcc0bb",
      1200: "#701b1b",
      1300: "#d75885",
      1400: "#d8002714",
      1500: "#B400A2",
    },
    green: {
      100: "#0d3f24",
      200: "#125933",
      300: "#1b834b",
      400: "#24ad63",
      500: "#2ed47a",
      600: "#5add96",
      700: "#84e6b1",
      800: "#afeecc",
      900: "#eafbf2",
      1000: "#2c82341a",
    },
    yellow: {
      100: "#7a4c00",
      200: "#ad6c00",
      300: "#e08b00",
      400: "#ffa614",
      500: "#ffb946",
      600: "#ffcd7a",
      700: "#ffe0ad",
      800: "#ffeccc",
      900: "#fff9f0",
      1000: "#d0cd852b",
      1100: "#806B1A",
    },
    blueGreen: {
      100: "#042C3D",
      200: "#64abb5",
      300: "#64abb547",
      400: "#E4F1F9",
    },
    gray: {
      100: "#666474",
      200: "#AEADB4",
      300: "#7964b514",
      400: "#c5c4c9",
      500: "#516276",
    },
    notification: {
      hover: "#FFD6D2",
      unread: "#FFE7E4",
      "unread-dot": "#F53A29",
      read: "#E7F5FE",
      "read-dot": "#109CF1",
    },
  },
  shadows: [
    ...themeShadows.shadows,
    "0px 4px 24px rgba(112, 144, 176, .3)",
    "0.6px 0.4px 1.9px -29px rgba(0, 0, 0, 0.307),2px 1.3px 6.5px -29px rgba(0, 0, 0, 0.453),9px 6px 29px -29px rgba(0, 0, 0, 0.76)",
  ],
  components: {
    MuiSkeleton: {
      defaultProps: {
        animation: "wave",
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: "#7964b5",
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: 10,
          // background: "rgba(255, 255, 255, 0.09)",
          // backdropFilter: "blur(1.8px)",
          // border: "1px solid rgba(255, 255, 255, 0.23)",
          padding: 20,
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          fontSize: ".9rem !important",
          height: 42,
          backgroundColor: "#fff",
          gap: 10,
          "&:hover": {
            borderColor: "#7964b5",
            boxShadow: "0px 0px 5px rgba(123, 97, 255, 0.25)",
          },
          "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
            {
              borderColor: "#7964b5",
              borderWidth: "1px",
              boxShadow: "0px 0px 5px rgba(123, 97, 255, 0.25)",
            },
        },
        input: {
          "&::placeholder": {
            fontSize: "0.8rem",
            fontWeight: 500,
            color: "#797783",
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: "#7964b5",
            boxShadow: "0px 0px 5px rgba(123, 97, 255, 0.25)",
          },
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "#7964b5",
            borderWidth: "1px",
            boxShadow: "0px 0px 5px rgba(123, 97, 255, 0.25)",
          },
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          width: "fit-content",
        },
        label: {
          fontSize: ".8rem",
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          "&.Mui-checked": {
            color: "#7964b5",
          },
          "& + .MuiFormControlLabel-label": {
            color: "#7964b5",
          },
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          padding: "5px",
          "&.Mui-checked": {
            color: "#7964b5",
          },
          "& + .MuiFormControlLabel-label": {
            color: "#7964b5",
          },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            borderColor: "#7964b5",
            boxShadow: "0px 0px 5px rgba(123, 97, 255, 0.25)",
          },
          "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
            {
              borderColor: "#7964b5",
              borderWidth: "1px",
              boxShadow: "0px 0px 5px rgba(123, 97, 255, 0.25)",
            },
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          marginLeft: 0,
          fontSize: ".7rem",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          minHeight: 45,
          fontWeight: 600,
        },
      },
    },
    MuiTab: {
      variants: [
        {
          props: { variant: "st-tab-custom-1" },
          style: ({ theme, type, color, bgcolor }) => ({
            fontSize: ".8rem",
            fontWeight: 500,
            minHeight: 42,
            borderRadius: 5,
            textTransform: "none",
            backgroundColor: theme.palette.grey[200],
            color: theme.palette.grey[800],
            "&.Mui-selected": {
              backgroundColor: theme.palette[type][bgcolor],
              color: `${theme.palette[type][color]} !important`,
            },
          }),
        },
      ],
    },
  },
});
