import React, { useRef } from "react";
import { useClickAway } from "react-use";
import Triangle from "../../assets/floatingTriangle.png";
import Flag from "react-flagkit";

import "./_FlagMenu.scss";

const FlagMenu = ({ handleShowFlags, handleChangeFlag, countries }) => {
  const flagContainerRef = useRef();

  useClickAway(flagContainerRef, () => {
    handleShowFlags();
  });

  return (
    <div ref={flagContainerRef} className="flag-menu-container">
      <div className="flag-menu">
        <img src={Triangle} alt="triangle" />
        <div className="flags">
          <div
            className="country-item-container"
            onClick={() => handleChangeFlag("US")}
          >
            <Flag country={"US"} size={20} />
            <span>United States</span>
          </div>
          <div
            className="country-item-container"
            onClick={() => handleChangeFlag("MX")}
          >
            <Flag country={"MX"} size={20} />

            <span>Mexico</span>
          </div>
          <div
            className="country-item-container"
            onClick={() => handleChangeFlag("PH")}
          >
            <Flag country={"PH"} size={20} />

            <span>Philippines</span>
          </div>
          <div
            className="country-item-container"
            onClick={() => handleChangeFlag("PL")}
          >
            <Flag country={"PL"} size={20} />

            <span>Poland</span>
          </div>
          <div
            className="country-item-container"
            onClick={() => handleChangeFlag("VN")}
          >
            <Flag country={"VN"} size={20} />
            <span>Vietnam</span>
          </div>
          <div
            className="country-item-container"
            onClick={() => handleChangeFlag("HK")}
          >
            <Flag country={"HK"} size={20} />
            <span>Hong Kong</span>
          </div>
          <div
            className="country-item-container"
            onClick={() => handleChangeFlag("GB")}
          >
            <Flag country={"GB"} size={20} />
            <span>United Kingdom</span>
          </div>
          <div
            className="country-item-container"
            onClick={() => handleChangeFlag("CA")}
          >
            <Flag country={"CA"} size={20} />
            <span>Canada</span>
          </div>
          <div
            className="country-item-container"
            onClick={() => handleChangeFlag("CO")}
          >
            <Flag country={"CO"} size={20} />
            <span>Columbia</span>
          </div>
          <div
            className="country-item-container"
            onClick={() => handleChangeFlag("IN")}
          >
            <Flag country={"IN"} size={20} />
            <span>India</span>
          </div>
        </div>
        {/* <div className="flags custom-scrollbar">
          {countries.map(({ id, country_abrv, country }) => (
            <div
              key={id}
              className="country-item-container"
              onClick={() => handleChangeFlag(country_abrv)}
            >
              <Flag country={country_abrv} size={20} />
              <span>{country}</span>
            </div>
          ))}
        </div> */}
      </div>
    </div>
  );
};

export default React.memo(FlagMenu);
