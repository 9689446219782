
import React from 'react';
import "./_CustomToggleCheckbox.scss";

const CustomToggleCheckbox = ({ text, toggleStatus, event }) => {
  return <label className={`custom-toggle-container custom-toggle-check-${toggleStatus}`} htmlFor="checkbox-toggle">
      <input type="checkbox" name="toggle" id="checkbox-toggle" onChange={event} />
      <div>
          <div></div>
      </div>
      <span>{text}</span>
  </label>;
};

export default CustomToggleCheckbox;