import React from 'react'

import "./_Notification.scss";

const Notification = ({ text, type }) => {
  return (
    <div className={`notification-container notification-${type ? type : "default"}`}>
        <p>{text}</p>
    </div>
  )
}

export default Notification