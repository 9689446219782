import React from "react";
import Skeleton from "react-loading-skeleton";

import "./_HeadingTitle.scss";

const HeadingTitle = ({
  text,
  errorDisplay,
  subText,
  isPrimaryColor,
  children,
  loading,
}) => {
  return (
    <div className="heading-container">
      <div>
        {loading ? (
          <p>
            <Skeleton width={120} />
          </p>
        ) : (
          <p
            className={`heading-title ${
              isPrimaryColor ? "heading-primary" : ""
            }`}
          >
            {text} {errorDisplay ? <span>{errorDisplay}</span> : ""}
          </p>
        )}
        {children && children}
      </div>
      {subText && (
        <p
          className={`sub-title ${isPrimaryColor ? "heading-primary-sub" : ""}`}
        >
          {subText}
        </p>
      )}
    </div>
  );
};

export default HeadingTitle;
