import React from "react";
import "./_Steps.scss";

import { BigCheckIcon } from "../../utils/SvgIcons";

const Steps = ({ text, index, isCurrent, isDone }) => {
  return (
    <div
      className={`step-container ${isCurrent === index ? "is-current" : ""} ${
        isDone.includes(index) ? "is-done" : ""
      }`}
    >
      <div className="step-index">
        {isDone.includes(index) ? BigCheckIcon("#fff") : <span>{index}</span>}
      </div>
      <p>{text}</p>
    </div>
  );
};

export default Steps;
