import React, { useState, useRef } from "react";
import { useClickAway } from "react-use";
import Swal from "sweetalert2";

import { setUserStatus, getUserData } from "../../utils/Common";
import { SmallCheckIcon } from "../../utils/SvgIcons";
import { changeStatus } from "../../api";

import "./_UserStatus.scss";

const UserStatus = ({ status, curStatus, setCurStatus }) => {
  const fromLocal = getUserData();
  const [openMenu, setOpenMenu] = useState(false);

  const menuRef = useRef();

  const handleOpenMenu = () => setOpenMenu((prev) => !prev);

  const handleSelectStatus = async (index, status) => {
    try {
      await changeStatus({ status }, fromLocal.token);

      setCurStatus(index);
      setUserStatus(index);
      setOpenMenu(false);
    } catch (err) {
      if (err.response.status === 422 || err.response.status === 401) {
        Swal.fire({
          text: err.response.message,
          icon: "warning",
          confirmButtonText: "Ok",
          confirmButtonColor: "#55428A",
        });

        return;
      }

      if (err.response.status === 500) {
        console.log(err.response.status);
      }
    }
  };

  useClickAway(menuRef, () => handleOpenMenu());

  return (
    <div className="user-status-container">
      <p>Status:</p>
      <div className="user-status">
        <span id="curr-status" onClick={handleOpenMenu}>
          {status[curStatus]?.text || "Active"}
        </span>
        {openMenu && (
          <div className="user-status-menu" ref={menuRef}>
            {status.map((item, i) => (
              <div
                onClick={() => handleSelectStatus(i, item.text.toUpperCase())}
                key={item.text}
              >
                <span style={{ background: item.color }}></span>
                <span>{item.text}</span>
                {i === curStatus && SmallCheckIcon("#7964b5")}
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default UserStatus;
