import React, { useState, useRef, useEffect, useContext } from "react";
import Swal from "sweetalert2";
import Slider from "react-animated-slider";
import "react-animated-slider/build/horizontal.css";
import Skeleton from "react-loading-skeleton";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";

import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import CloseIcon from "@mui/icons-material/Close";

import { onMessageListener } from "../../config/firebase";
import { setVisitNotification } from "../../api";
import { getUserData } from "../../utils/Common";
import { DataContext } from "../../context";
import OnboardingVideo from "../Onboarding/OnboardingVideo";

import {
  useGetNotifications,
  useVisitedNotification,
} from "../../query/NotificationQueries";

import "./_NotificationBanner.scss";

const NotificationBanner = ({ initNotifications, setNotifBanner }) => {
  const sliderRef = useRef();
  const fromLocal = getUserData();
  const [currentSlide, setCurrentSlide] = useState(0);
  const [notifList, setNotifList] = useState([]);
  const [showOnboard, setShowOnboard] = useState(false);
  const { setPhoneVerify, isEnglishAssComplete } = useContext(DataContext);

  const { data, refetch } = useGetNotifications(
    fromLocal?.token,
    fromLocal ? true : false
  );
  const { mutateAsync } = useVisitedNotification();

  const handleClickHere = async (item, index) => {
    await mutateAsync({ itemId: item.id, userToken: fromLocal.token });
    refetch();
    if (item.title.toLowerCase().includes("mobile number verification")) {
      setPhoneVerify(true);
      return;
    }

    if (item.title.includes("Welcome to Satellite Teams!")) {
      // setShowOnboard(true);
      return;
    }

    window.open(item.link, "_blank");
    // history.push(item.link);
    // setVisitNotification(item.id, fromLocal.token)
    //   .then((res) => {
    //     if (item.title.toLowerCase().includes("mobile number verification")) {
    //       setPhoneVerify(true);

    //       if (item.visit === 0 || item.visit === "0") {
    //         const listInstance = [...notifList];
    //         const itemInstance = { ...item, visit: 1 };

    //         listInstance.splice(index, 1, itemInstance);

    //         setNotifList(listInstance);
    //       }

    //       return;
    //     }

    //     if (
    //       item.action === 1 &&
    //       item.title.includes("Welcome to Satellite Teams!")
    //     ) {
    //       if (item.visit === 0 || item.visit === "0") {
    //         const listInstance = [...notifList];
    //         const itemInstance = { ...item, visit: 1 };

    //         listInstance.splice(index, 1, itemInstance);

    //         setNotifList(listInstance);
    //       }

    //       return;
    //     }

    //     window.open(item.link, "_blank");
    //     if (item.visit === 0 || item.visit === "0") {
    //       const listInstance = [...notifList];
    //       const itemInstance = { ...item, visit: 1 };

    //       listInstance.splice(index, 1, itemInstance);

    //       setNotifList(listInstance);
    //     }
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //     if (err.response.status === 422 || err.response.status === 401) {
    //       Swal.fire({
    //         text: err.response.message,
    //         icon: "warning",
    //         confirmButtonText: "Ok",
    //         confirmButtonColor: "#55428A",
    //       });

    //       return;
    //     }

    //     if (err.response.status === 500) {
    //       console.log(err.response.status);
    //     }
    //   });
  };

  // onMessageListener()
  //   .then((payload) => {
  //     // const newNotif = payload.data.filter((item) => item.visit === 0);
  //     setNotifList((prev) => [
  //       {
  //         ...payload.data,
  //         title:
  //           payload?.data?.title === "Online proficiency assessment"
  //             ? "English proficiency"
  //             : payload?.data?.title,
  //         visit: payload?.data?.visit ? Number(payload?.data?.visit) : null,
  //         action: payload?.data?.action ? Number(payload?.data?.action) : null,
  //       },
  //       ...prev,
  //     ]);
  //   })
  //   .catch((err) => console.log("failed: ", err));

  // useEffect(() => {
  //   console.log("notif banner");
  //   // if (!isLoading) {
  //   //   setNotifList(data.notifications);
  //   // }
  // }, [data]);

  // useEffect(() => {
  //   setNotifList(initNotifications);
  // }, [initNotifications]);

  useEffect(() => {
    if (fromLocal?.token) refetch();
  }, [fromLocal?.token]);

  useEffect(() => {
    const isCompleted = isEnglishAssComplete
      ? isEnglishAssComplete
      : JSON.parse(sessionStorage.getItem("english-assessment-complete"));
    setNotifList(
      isCompleted
        ? data?.notifications?.filter(
            (item) => item.title !== "English proficiency"
          )
        : data?.notifications
    );
  }, [isEnglishAssComplete, data]);

  return notifList.length === 0 ? (
    <div></div>
  ) : (
    <>
      {showOnboard && <OnboardingVideo setShowOnboard={setShowOnboard} />}
      <Box
        sx={{
          padding: "10px 20px 10px 30px",
          backgroundColor: "#F0EEF7",
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Stack direction="row" alignItems="center" spacing={1} width="100%">
            <Stack direction="row" alignItems="center" spacing={1}>
              <button
                onClick={() => sliderRef.current.previous()}
                style={{
                  background: "transparent",
                  zIndex: 5,
                  cursor: "pointer",
                  marginRight: 15,
                }}
              >
                <ArrowBackIosIcon
                  sx={(theme) => ({
                    color: "#000",
                    fontSize: ".95rem",
                  })}
                />
              </button>
              <button
                style={{
                  background: "transparent",
                  zIndex: 5,
                  cursor: "pointer",
                }}
                onClick={() => sliderRef.current.next()}
              >
                <ArrowForwardIosIcon
                  sx={(theme) => ({
                    color: "#000",
                    fontSize: ".95rem",
                  })}
                />
              </button>
              <Box width={notifList?.length >= 10 ? 70 : 50}>
                <Typography
                  variant="body1"
                  sx={(theme) => ({
                    color: "#000",
                    fontSize: ".8rem",
                    fontWeight: 600,
                    marginRight: "10px",
                  })}
                >
                  {`${currentSlide + 1} of ${notifList?.length}`}
                </Typography>
              </Box>
            </Stack>
            <Box
              sx={{
                height: "28px",
                width: "100%",
              }}
            >
              <Slider
                classNames={{
                  slider: "custom-slider",
                }}
                duration={2000}
                // autoplay={3000}
                // infinite
                ref={sliderRef}
                previousButton={null}
                nextButton={null}
                onSlideChange={(data) => setCurrentSlide(data.slideIndex)}
                direction="vertical"
              >
                {data &&
                  notifList.map((item, index) => (
                    <Stack
                      key={index}
                      direction="row"
                      spacing={2}
                      alignItems="center"
                      sx={{
                        height: "100%",
                      }}
                    >
                      <Typography
                        variant="body1"
                        sx={(theme) => ({
                          color: "#000",
                          fontSize: ".8rem",
                          fontWeight: 600,
                          marginRight: "10px",
                        })}
                      >
                        {`${item.title}`}
                      </Typography>
                      <Typography
                        variant="body1"
                        sx={(theme) => ({
                          color: "#000",
                          fontSize: ".75rem",
                          marginRight: "10px",
                        })}
                      >
                        {item.highlight}
                      </Typography>
                      {
                        <Typography
                          variant="body1"
                          component="span"
                          sx={(theme) => ({
                            background: "#9CD874",
                            padding: "5px 8px",
                            borderRadius: "5px",
                            fontSize: ".75rem",
                            color: "#fff",
                            cursor: "pointer",
                          })}
                          onClick={() => handleClickHere(item, index)}
                        >
                          Click here
                        </Typography>
                      }
                    </Stack>
                  ))}
              </Slider>
            </Box>
          </Stack>
          <button
            onClick={() => setNotifBanner(false)}
            style={{
              background: "transparent",
              zIndex: 5,
              cursor: "pointer",
            }}
          >
            <CloseIcon
              sx={(theme) => ({
                color: "#000",
                fontSize: "1.4rem",
              })}
            />
          </button>
        </Stack>
      </Box>
    </>
  );
};

export default NotificationBanner;
