import React from "react";

import "./_HeaderIconBtn.scss";

const HeaderIconBtn = ({ icon, event }) => {
  return (
    <button onClick={event} className="icon-btn-container">
      {icon}
    </button>
  );
};

export default HeaderIconBtn;
