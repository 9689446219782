import { useQuery, useMutation } from "react-query";

import { updateNotificationList, setVisitNotification } from "../api";
import { queryClient } from "../config/queryClient";

export const queryKeyGetNotifications = "notifications";

export function useGetNotifications(userToken, enabled = false) {
  return useQuery(
    queryKeyGetNotifications,
    async (props) => {
      try {
        const res = await updateNotificationList(userToken);
        const newNotif = res.data.data.filter((item) => item.visit === 0);
        let notifs = res.data.data.map((item) => ({
          ...item,
          title:
            item.title === "Online proficiency assessment"
              ? "English proficiency"
              : item.title,
        }));

        return { notifications: notifs, counts: newNotif.length };
      } catch (error) {
        throw error;
      }
    },
    {
      refetchOnMount: false,
      enabled,
      // initialData: {
      //   notifications: [],
      //   counts: 0,
      // },
    }
  );
}

export function useVisitedNotification() {
  async function mutateFn(props) {
    try {
      await setVisitNotification(props.itemId, props.userToken);
    } catch (error) {
      throw new Error(error);
    }
  }

  return useMutation(mutateFn);
}
