import React from "react";
import TimePicker from "rc-time-picker";
import "rc-time-picker/assets/index.css";

import { ClockIcon } from "../../utils/SvgIcons";
import "./_CustomTimePicker.scss";

const CustomTimePicker = ({
  label,
  name,
  defaultValue,
  placeholder,
  isDisabled,
  onChangeEvent,
  error,
}) => {
  return (
    <div>
      {label && <label htmlFor={name}>{label}</label>}
      <TimePicker
        name="name"
        className={`custom-timepicker-input ${
          error ? "custom-timepicker-error" : ""
        }`}
        popupClassName="custom-timepicker-panel"
        showSecond={false}
        format="h:mm a"
        use12Hours
        defaultValue={defaultValue && defaultValue}
        onChange={onChangeEvent}
        clearIcon={
          <i
            style={{
              display: "none",
            }}
          >
            {ClockIcon("#55428a")}
          </i>
        }
        inputIcon={
          <i
            style={{
              position: "absolute",
              width: "24px",
              right: 5,
              top: 0,
              bottom: 0,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              cursor: "pointer",
            }}
          >
            {ClockIcon("#55428a")}
          </i>
        }
      />
      {error && <p className="custom-timepicker-error-notif">{error}</p>}
    </div>
  );
};

export default CustomTimePicker;
