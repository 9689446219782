import React from 'react'
import { Oval } from 'react-loader-spinner';

import "./_IconButton.scss";

const IconButton = ({ icon, type, color, hasOutline, size, disabled, loading, isTransparent, event }) => {
  return (
    <button type={type ? type : "button"} onClick={event} className={`button-icon-container ${isTransparent ? "button-icon-transparent" : ""} button-icon-${color ? color : "default"} ${hasOutline ? "button-icon-outlined" : ""} button-icon-size-${size ? size : "default"} ${loading ? "button-icon-loading" : ""}`} disabled={disabled}>
        {icon}
    </button>
  )
}

export default IconButton