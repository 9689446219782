import axios from "axios";

const API = axios.create({
  baseURL: process.env.REACT_APP_DOMAIN,
  headers: { Accept: "application/json", "Content-Type": "application/json" },
});

// post methods starts
export const userRegistration = (
  formData,
  utm_campaign,
  utm_source,
  utm_medium
) =>
  API.post(
    `/register?utm_source=${utm_source}&utm_medium=${utm_medium}&utm_campaign=${utm_campaign}
`,
    formData
  );
export const userLogin = (formData) => API.post("/login", formData);
export const emailVerify = (formData) => API.post("/email-verify", formData);
export const savePitch = (formData, token) =>
  API.post("/employee/pitch", formData, {
    headers: { Authorization: `Bearer ${token}` },
  });
export const saveQuestionAnswers = (formData, token) =>
  API.post("/employee/questionnaire", formData, {
    headers: { Authorization: `Bearer ${token}` },
  });
export const forgotPassword = (formData) =>
  API.post("/forgot-password", formData);
export const checkToken = (formData) => API.post("/check-token", formData);
export const resetPassword = (formData) =>
  API.post("/reset-password", formData);
export const saveDocument = (formData, token) =>
  API.post("/employee/document", formData, {
    headers: { Authorization: `Bearer ${token}` },
  });
export const saveResume = (formData, token) =>
  API.post("/employee/resume", formData, {
    headers: { Authorization: `Bearer ${token}` },
  });
export const saveAgreeTerms = (token) =>
  API.post(
    "/agreed/terms",
    {},
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
export const saveProfile = (formData, token) =>
  API.post("/employee/profile", formData, {
    headers: { Authorization: `Bearer ${token}` },
  });
export const saveIdentifications = (formData, token) =>
  API.post("/employee/identification", formData, {
    headers: { Authorization: `Bearer ${token}` },
  });
export const saveTextAssesment = (formData, token) =>
  API.post("/employee/speak", formData, {
    headers: { Authorization: `Bearer ${token}` },
  });
export const userLogout = (token) =>
  API.post(
    "/logout",
    {},
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
export const checkTokenApplicantAgent = (formData) =>
  API.post("/login/applicant", formData);
export const updateApplicantUsernamePass = (formData) =>
  API.post("/login/applicant", formData);
export const verifyOtp = (formData) => API.post("/verify-code", formData);
export const resendOtp = (formData) => API.post("/resend-otp", formData);
export const savePortfolio = (formData, token) =>
  API.post(`/employee/portfolio`, formData, {
    headers: { Authorization: `Bearer ${token}` },
  });
// post methods end

// get methods starts
export const getTermsPrivacy = (controller) =>
  API.get(`term-conditions/EMPLOYEE`, { signal: controller.signal });
export const fetchCountries = (controller) =>
  API.get("/country", { signal: controller.signal });
export const getPitchQuestion = (token, controller) =>
  API.get(
    "/pitch/random",
    { headers: { Authorization: `Bearer ${token}` } },
    { signal: controller.signal }
  );
export const getPitchVideos = (id, token, controller) =>
  API.get(
    `/employee/pitch/${id}`,
    {
      headers: { Authorization: `Bearer ${token}` },
    },
    { signal: controller.signal }
  );
export const getQuestionaires = (jobId, token) =>
  API.get(`/employee/questionnaire/${jobId}`, {
    headers: { Authorization: `Bearer ${token}` },
  });
export const getJobPositions = (token, controller) =>
  API.get(
    "/configuration/position",
    {
      headers: { Authorization: `Bearer ${token}` },
    },
    { signal: controller.signal }
  );
export const getDocuments = (userId, token, controller) =>
  API.get(
    `/employee/document/${userId}`,
    { headers: { Authorization: `Bearer ${token}` } },
    { signal: controller.signal }
  );
export const getProfileDetails = (userId, token, controller) =>
  API.get(
    `/employee/profile/${userId}`,
    { headers: { Authorization: `Bearer ${token}` } },
    { signal: controller.signal }
  );
export const getUserIdentification = (userId, token, controller) =>
  API.get(
    `/employee/identification/${userId}`,
    { headers: { Authorization: `Bearer ${token}` } },
    { signal: controller.signal }
  );
export const getUserCredentials = (id, token, controller) =>
  API.get(
    `/employee/user/${id}`,
    { headers: { Authorization: `Bearer ${token}` } },
    { signal: controller.signal }
  );
export const searchJob = (searchValue, token) =>
  API.get(`/job/${searchValue}`, {
    headers: { Authorization: `Bearer ${token}` },
  });
export const getCompensation = (token, controller) =>
  API.get(
    `/configuration/compensation`,
    {
      headers: { Authorization: `Bearer ${token}` },
    },
    { signal: controller.signal }
  );
export const getWorkPreference = (token, controller) =>
  API.get(
    `/configuration/work-preference`,
    { headers: { Authorization: `Bearer ${token}` } },
    { signal: controller.signal }
  );
export const getJobType = (token, controller) =>
  API.get(
    `/configuration/job-type`,
    { headers: { Authorization: `Bearer ${token}` } },
    { signal: controller.signal }
  );
export const getLanguage = (token, controller) =>
  API.get(
    `/configuration/languange`,
    { headers: { Authorization: `Bearer ${token}` } },
    { signal: controller.signal }
  );
export const getEducation = (token, controller) =>
  API.get(
    `/configuration/educational-stage`,
    { headers: { Authorization: `Bearer ${token}` } },
    { signal: controller.signal }
  );
export const getRate = (token, controller) =>
  API.get(
    `/configuration/rate`,
    { headers: { Authorization: `Bearer ${token}` } },
    { signal: controller.signal }
  );
export const getProficiency = (token, controller) =>
  API.get(
    `/configuration/proficiency`,
    { headers: { Authorization: `Bearer ${token}` } },
    { signal: controller.signal }
  );
export const getJobRole = (id, token, controller) =>
  API.get(
    `/job/role/${id}`,
    { headers: { Authorization: `Bearer ${token}` } },
    { signal: controller.signal }
  );
export const getCommonSkills = (token, controller) =>
  API.get(
    "/configuration/position-common-skill",
    { headers: { Authorization: `Bearer ${token}` } },
    { signal: controller.signal }
  );
export const getRegion = (id, controller) =>
  API.get(`/region/${id}`, { signal: controller.signal });
export const getState = (id, controller) =>
  API.get(`/state/${id}`, { signal: controller.signal });
export const getCity = (id, controller) =>
  API.get(`/city/${id}`, { signal: controller.signal });
export const getIdentifications = (token, controller) =>
  API.get(
    "/configuration/identification",
    { headers: { Authorization: `Bearer ${token}` } },
    { signal: controller.signal }
  );
export const getDemoVideo = (token, controller) =>
  API.get(
    "/instruction/pitch",
    { headers: { Authorization: `Bearer ${token}` } },
    { signal: controller.signal }
  );
export const getTextSpeech = (token, controller) =>
  API.get(
    "/speak",
    { headers: { Authorization: `Bearer ${token}` } },
    { signal: controller.signal }
  );
export const getUserTextSpeechOutput = (token, controller) =>
  API.get(
    "/employee/speak",
    { headers: { Authorization: `Bearer ${token}` } },
    { signal: controller.signal }
  );
export const getMeetingList = (date, token, controller) =>
  API.get(
    `/employee/meeting?start=${date.start}&end=${date.end}
  `,
    { headers: { Authorization: `Bearer ${token}` } },
    { signal: controller.signal }
  );
export const getSchoolList = (token, controller) =>
  API.get(
    `/configuration/school/ph`,
    { headers: { Authorization: `Bearer ${token}` } },
    { signal: controller.signal }
  );
export const getNewsFeed = (token, controller) =>
  API.get(
    "/news-feed",
    { headers: { Authorization: `Bearer ${token}` } },
    { signal: controller.signal }
  );
export const getReferralLink = (token, controller) =>
  API.get(
    "/referral",
    { headers: { Authorization: `Bearer ${token}` } },
    { signal: controller.signal }
  );
export const getResumeDetails = (userId, token, controller) =>
  API.get(
    `/employee/resume/${userId}`,
    { headers: { Authorization: `Bearer ${token}` } },
    { signal: controller.signal }
  );
export const getDashboard = (token, controller) =>
  API.get(
    `/employee/dashboard`,
    { headers: { Authorization: `Bearer ${token}` } },
    { signal: controller.signal }
  );
export const searchSchool = (searchValue, token) =>
  API.get(`/school/${searchValue}`, {
    headers: { Authorization: `Bearer ${token}` },
  });
export const verifyTokenExist = (token) => API.get(`/verify-token-wo/${token}`);
export const getCompanyProfile = (controller) =>
  API.get("/company/profile", null, { signal: controller.signal });
export const getPositionCategories = (token, controller) =>
  API.get(
    "/configuration/job-position",
    { headers: { Authorization: `Bearer ${token}` } },
    { signal: controller.signal }
  );
export const getScheduleShift = (token, controller) =>
  API.get(
    `/configuration/job-shift`,
    { headers: { Authorization: `Bearer ${token}` } },
    { signal: controller.signal }
  );
export const getJobExperience = (token, controller) =>
  API.get(
    `/configuration/job-experience`,
    { headers: { Authorization: `Bearer ${token}` } },
    { signal: controller.signal }
  );
export const getJobCareerLevel = (token, controller) =>
  API.get(
    `/configuration/job-career-level`,
    { headers: { Authorization: `Bearer ${token}` } },
    { signal: controller.signal }
  );
export const updateNotificationList = (token) =>
  API.get("/notification", {
    headers: { Authorization: `Bearer ${token}` },
  });
export const getJobOfferFile = (id, token, controller) =>
  API.get(
    `/job-offer/get/${id}`,
    {
      headers: { Authorization: `Bearer ${token}` },
    },
    { signal: controller.signal }
  );
export const getSignedJobOffer = (id, token) =>
  API.get(`/job-offer/signed/${id}`, {
    headers: { Authorization: `Bearer ${token}` },
  });
export const getOnboardingVideo = (token, controller) =>
  API.get(
    `/configuration/company-onboarding`,
    {
      headers: { Authorization: `Bearer ${token}` },
    },
    { signal: controller.signal }
  );
export const agreeOnboarding = (token, controller) =>
  API.put(`/onboard`, null, {
    headers: { Authorization: `Bearer ${token}` },
  });
export const getPortfolio = (pathParam, queryParam, token, controller) =>
  API.get(
    `/employee/portfolio/${pathParam}?${queryParam}`,
    {
      headers: { Authorization: `Bearer ${token}` },
    },
    { signal: controller.signal }
  );
export const checkSalaryRange = (id, currency, amount, token) =>
  API.get(
    `/check/salary?position_id=${id}&currency=${currency}&amount=${amount}`,
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
// get methods end

// put methods starts
export const updateNextLink = (formData, token) =>
  API.put("/update/next-link", formData, {
    headers: { Authorization: `Bearer ${token}` },
  });
export const updateUserCredentials = (formData, token) =>
  API.put("/employee/user", formData, {
    headers: { Authorization: `Bearer ${token}` },
  });
export const updateUserProfile = (formData, token) =>
  API.put("/employee/profile", formData, {
    headers: { Authorization: `Bearer ${token}` },
  });
export const updateIdentifications = (formData, token) =>
  API.put("/employee/identification", formData, {
    headers: { Authorization: `Bearer ${token}` },
  });
export const getTextSpeechCategoryScore = (formData, token) =>
  API.put("/employee/speak", formData, {
    headers: { Authorization: `Bearer ${token}` },
  });
export const meetingAcceptDecline = (formData, token) =>
  API.put("/employee/meeting", formData, {
    headers: { Authorization: `Bearer ${token}` },
  });
export const updateResume = (formData, token) =>
  API.put("/employee/resume", formData, {
    headers: { Authorization: `Bearer ${token}` },
  });
export const setVisitNotification = (id, token) =>
  API.put(`/notification/${id}`, null, {
    headers: { Authorization: `Bearer ${token}` },
  });
export const setDisagreeJobOffer = (id, formData, token) =>
  API.put(`/job-offer/other/${id}`, formData, {
    headers: { Authorization: `Bearer ${token}` },
  });
export const changeStatus = (formData, token) =>
  API.put("change-status", formData, {
    headers: { Authorization: `Bearer ${token}` },
  });
export const retakeEnglishAssessment = (token) =>
  API.put(`/employee/speak/retake`, null, {
    headers: { Authorization: `Bearer ${token}` },
  });
// put methods end

// delete methods starts
export const deletePitchRecording = (pitchId, token) =>
  API.delete(`/employee/pitch/${pitchId}`, {
    headers: { Authorization: `Bearer ${token}` },
  });
export const deleteDocument = (docId, token) =>
  API.delete(`/employee/document/${docId}`, {
    headers: { Authorization: `Bearer ${token}` },
  });
export const deletePortfolio = (id, type, bulkIds, token) => {
  return API.delete(`/employee/portfolio/${id}/${type}`, {
    headers: { Authorization: `Bearer ${token}` },
  });
};
// delete methods end
