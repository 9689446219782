import React from "react";
import { Oval } from "react-loader-spinner";

const SectionLoader = ({ color1, color2 }) => {
  return (
    <div style={{ position: "relative", height: "50px" }}>
      <Oval
        color={color1}
        secondaryColor={color2}
        strokeWidth={4}
        height={40}
        width={40}
        wrapperStyle={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      />
    </div>
  );
};

export default SectionLoader;
