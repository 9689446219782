import React, { useContext } from "react";
import { Link } from "react-router-dom";
import "./_header.scss";

import BrandLogo from "../../assets/purple-brand-logo.svg";
import { DataContext } from "../../context";

const Header = () => {
  var brandLogo = require("../../assets/brandLogo.png");
  const { companyProfile } = useContext(DataContext);

  return (
    <div className="header">
      <a
        href="https://www.satelliteteams.com"
        // to={`/${replaceValueSpaceHyphen(
        //   fromLocal?.first_name
        // )}/dashboard`}
        style={{ textDecoration: "none" }}
      >
        <img
          className="brandLogo"
          src={companyProfile ? companyProfile.logo_applicant : BrandLogo}
        />
      </a>
    </div>
  );
};

export default Header;
