import React, { useState } from "react";
import { Calendar } from "react-date-range";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import _ from "lodash";

import "./_CustomDatePicker.scss";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { CalendarIcon, CloseIcon } from "../../utils/SvgIcons";
import useDetectDevice from "../../utils/useDetectDevice";
import InputGeneric from "./InputGeneric";

export const CalendarModal = ({
  calendarEvent,
  setShowCalendar,
  valueFrom,
  valueTo,
  indexKey,
  isDisabled,
  formKey,
}) => {
  return (
    <div className="custom-date-calendar">
      <div className="custom-date-calendar-inner">
        <span onClick={() => setShowCalendar((prev) => !prev)}>
          {CloseIcon("#f53a29")}
        </span>
        <Calendar
          maxDate={new Date(moment().endOf("year"))}
          onChange={(item) => {
            calendarEvent(item, indexKey, formKey);
            setShowCalendar((prev) => !prev);
          }}
          date={formKey === "from" ? valueFrom : valueTo}
          color="#a698cd"
        />
      </div>
    </div>
  );
};

const CustomDatePicker = ({
  label,
  error,
  icon,
  name,
  placeholder,
  valueFrom,
  valueTo,
  event,
  calendarEvent,
  changeRaw,
  indexKey,
  valueInput,
  isDisabled,
  formKey,
  dateString = "",
}) => {
  const browser = useDetectDevice("browser");
  const [showCalendar, setShowCalendar] = useState(false);

  return (
    <div className="custom-datepicker-container">
      {label && <label htmlFor={name}>{label}</label>}
      <div>
        <div
          className="datepicker-icon"
          onClick={isDisabled ? null : () => setShowCalendar(!showCalendar)}
        >
          {CalendarIcon(isDisabled ? "#c5c4c9" : "#55428a")}
        </div>
        {/* {browser?.name !== "Safari" && (
          <div
            className="datepicker-icon"
            onClick={isDisabled ? null : () => setShowCalendar(!showCalendar)}
          >
            {CalendarIcon(isDisabled ? "#c5c4c9" : "#55428a")}
          </div>
        )} */}
        {/* {browser?.name === "Safari" ? (
          <InputGeneric
            type="text"
            value={valueInput ? moment(valueInput).format("MM/DD/yyyy") : ""}
            placeholder={placeholder}
            isDisabled={isDisabled}
            clickEvt={() => setShowCalendar(true)}
            readOnly={true}
          />
        ) : ( */}
        <DatePicker
          // dateFormat="MM/dd/yyyy"
          autoComplete="off"
          selected={isDisabled ? null : valueInput}
          id={name}
          className={`custom-datepicker-input ${
            error ? "custom-datepicker-error" : ""
          } ${placeholder === "PRESENT" ? "custom-datepicker-string" : ""}`}
          placeholderText={placeholder}
          onChangeRaw={event}
          // readOnly={browser?.name === "Safari" ? true : false}
          // onInputClick={() =>
          //   browser?.name === "Safari" ? setShowCalendar(true) : null
          // }
          open={false}
          // open={browser?.name === "Chrome" ? showCalendar : false}
          // open={showCalendar}
          // onChange={(date) => {
          //   calendarEvent(date, indexKey, formKey);
          // }}
          disabled={isDisabled}
          value={
            isDisabled
              ? null
              : typeof dateString === "string" && !_.isEmpty(dateString)
              ? dateString
              : valueInput
          }
        />
        {/* )} */}
        {/* {showCalendar && browser?.name !== "Safari" && ( */}
        {showCalendar && (
          <CalendarModal
            calendarEvent={calendarEvent}
            setShowCalendar={setShowCalendar}
            valueFrom={valueFrom}
            valueTo={valueTo}
            indexKey={indexKey}
            isDisabled={isDisabled}
            formKey={formKey}
          />
        )}
        {icon && icon}
      </div>
      {error && <p className="custom-datepicker-error-notif">{error}</p>}
    </div>
  );
};

export default CustomDatePicker;
