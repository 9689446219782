import React from "react";
import NoResultFoundIcon from "../../assets/no-result-found-icon.png";

const NoResultFoundComponent = ({ message = "No result found." }) => {
  return (
    <div style={{ display: "block", textAlign: "center" }}>
      <div>
        <img
          src={NoResultFoundIcon}
          alt="no-result-found-icon"
          style={{ width: 150 }}
        />
      </div>
      <div style={{ marginTop: 30 }}>{message}</div>
    </div>
  );
};

export default NoResultFoundComponent;
