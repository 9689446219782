import React, { useRef, useState, useEffect, useContext } from "react";
import { useClickAway } from "react-use";
import _ from "lodash";
import Swal from "sweetalert2";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import Triangle from "../../assets/floatingTriangle.png";
import { BellIcon } from "../../utils/SvgIcons";
import { HeaderIconBtn } from "../_reusables";
import NotifCard from "./NotifCard";
import {
  getUserTextSpeechOutput,
  getTextSpeech,
  getDocuments,
  getPitchVideos,
  updateNotificationList,
} from "../../api";
import { getUserData, replaceValueSpaceHyphen } from "../../utils/Common";
import "./_Notifications.scss";
import { onMessageListener } from "../../config/firebase";
import OnboardingVideo from "../Onboarding/OnboardingVideo";
import { DataContext } from "../../context";

import { useGetNotifications } from "../../query/NotificationQueries";

const Notifications = () => {
  const notificationRef = useRef(null);
  const { isEnglishAssComplete } = useContext(DataContext);
  const [showNotifications, setNotifications] = useState(false);
  const [notifCount, setNotifCount] = useState(0);
  const [notifList, setNotifList] = useState([]);
  const [showOnboard, setShowOnboard] = useState(false);

  const fromLocal = getUserData();

  const handleShowNotifications = () => setNotifications(!showNotifications);

  useClickAway(notificationRef, () => handleShowNotifications());

  const {
    data,
    refetch,
    isError,
    error: err,
  } = useGetNotifications(fromLocal.token, fromLocal ? true : false);

  onMessageListener()
    .then((payload) => {
      console.log(payload);
      // const newNotif = payload.data.filter((item) => item.visit === 0);
      // setNotificationList((prev) => [
      //   {
      //     ...payload.data,
      //     title:
      //       payload?.data?.title === "Online proficiency assessment"
      //         ? "English proficiency"
      //         : payload?.data?.title,
      //     visit: payload?.data?.visit ? Number(payload?.data?.visit) : null,
      //     action: payload?.data?.action ? Number(payload?.data?.action) : null,
      //   },
      //   ...prev,
      // ]);
      // setNotificationCount((prev) => prev + 1);
      if (fromLocal?.token) refetch();
    })
    .catch((err) => console.log("failed: ", err));

  // useEffect(() => {
  //   console.log("notif bell");
  // }, [data]);

  // useEffect(() => {
  //   if (!isLoading) {
  //     setNotificationList((prev) => [...prev, ...data.notifications]);
  //     setInitNotifications((prev) => [...prev, ...data.notifications]);
  //     setNotificationCount(data.count);
  //   }
  // }, [data]);

  // useEffect(() => {
  //   updateNotificationList(fromLocal.token)
  //     .then((res) => {
  //       const newNotif = res.data.data.filter((item) => item.visit === 0);
  //       const notifs = res.data.data.map((item) => ({
  //         ...item,
  //         title:
  //           item.title === "Online proficiency assessment"
  //             ? "English proficiency"
  //             : item.title,
  //       }));
  //       // console.log(newNotif);
  //       // setNotificationList(res.data.data);
  //       setNotificationList((prev) => [...prev, ...notifs]);
  //       setInitNotifications((prev) => [...prev, ...notifs]);
  //       setNotificationCount(newNotif.length);
  //     })
  //     .catch((err) => {
  //       if (err.response.status === 422 || err.response.status === 401) {
  //         Swal.fire({
  //           text: err.response.message,
  //           icon: "warning",
  //           confirmButtonText: "Ok",
  //           confirmButtonColor: "#55428A",
  //         });

  //         return;
  //       }

  //       if (err.response.status === 500) {
  //         console.log(err.response.status);
  //       }
  //     });
  // }, []);

  useEffect(() => {
    if (fromLocal?.token) refetch();
  }, [fromLocal?.token]);

  useEffect(() => {
    setNotifList(
      isEnglishAssComplete
        ? data?.notifications?.filter(
            (item) => item.title !== "English proficiency"
          )
        : data?.notifications
    );
    setNotifCount(isEnglishAssComplete ? data?.counts - 1 : data?.counts);
  }, [isEnglishAssComplete, data]);

  if (isError) {
    if (err.response.status === 422) {
      Swal.fire({
        text: err.response.message,
        icon: "warning",
        confirmButtonText: "Ok",
        confirmButtonColor: "#55428A",
      });
    }

    if (err.response.status === 500) {
      console.log(err.response.status);
    }
  }

  return (
    <>
      {showOnboard && <OnboardingVideo setShowOnboard={setShowOnboard} />}
      <div style={{ position: "relative" }}>
        {data &&
          notifCount > 0 &&
          fromLocal.next_link &&
          fromLocal.next_link.includes("/dashboard") && (
            <span className="notifications-count">{notifCount}</span>
          )}
        <HeaderIconBtn
          icon={BellIcon("#fff")}
          event={handleShowNotifications}
        />
        {showNotifications && (
          <div ref={notificationRef} className="notifications-wrapper">
            <div className="notifications-container">
              <img src={Triangle} alt="triangle" />
              <Box
                sx={(theme) => ({
                  padding: "3px 0px 10px",
                  backgroundColor: theme.palette.brandColor[500],
                  borderRadius: "10px",
                  border: "2px solid #fff",
                  boxShadow: 2,
                })}
              >
                <Typography
                  variant="body1"
                  sx={{
                    padding: "5px 10px",
                    fontSize: ".75rem",
                    color: "#fff",
                    fontWeight: "600",
                  }}
                >
                  NOTIFICATIONS
                </Typography>
                <div className="notifications">
                  {data &&
                  notifList.length > 0 &&
                  fromLocal.next_link &&
                  fromLocal.next_link.includes("/dashboard") ? (
                    notifList.map((item, i) => (
                      <NotifCard
                        key={i}
                        item={item}
                        setShowOnboard={setShowOnboard}
                        setNotifications={setNotifications}
                        refetch={refetch}
                      />
                    ))
                  ) : (
                    <p>No Notifications</p>
                  )}
                  {/* <NotifCard /> */}
                </div>
              </Box>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Notifications;
