import React, { useState, useContext, useEffect } from "react";
import Flag from "react-flagkit";

import FlagMenu from "./FlagMenu";
import { getUserData } from "../../utils/Common";
import { DataContext } from "../../context";
import { fetchCountries } from "../../api";

const FlagContainer = () => {
  const fromLocal = getUserData();
  const { countriesList, setCountriesList } = useContext(DataContext);
  // const [currentFLag, setCurrentFlag] = useState(fromLocal.country.country);
  const [currentFLag, setCurrentFlag] = useState("PH");

  const [showFlagMenu, setFlagMenu] = useState(false);

  const handleShowFlags = () => setFlagMenu(!showFlagMenu);

  const handleChangeFlag = (country) => {
    setCurrentFlag(country);
  };

  useEffect(() => {
    const controller = new AbortController();
    if (currentFLag === null) {
      setCurrentFlag(fromLocal.country.country);
    }

    if (countriesList.length === 0) {
      fetchCountries(controller).then((res) => {
        if (res.data.success) {
          setCountriesList(res.data.data);
        }
      });
    }
  }, []);

  return (
    <div
      style={{ position: "relative" }}
      className="translate-thumb"
      onClick={handleShowFlags}
    >
      <div>
        <Flag country={currentFLag} size={25} />
      </div>

      {showFlagMenu && (
        <FlagMenu
          handleShowFlags={handleShowFlags}
          handleChangeFlag={handleChangeFlag}
          countries={countriesList}
        />
      )}
    </div>
  );
};

export default FlagContainer;
