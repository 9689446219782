import React, { useState, useContext, useEffect } from "react";
import _ from "lodash";
import { useHistory, Link } from "react-router-dom";

import "./_InnerHeader.scss";
import { MoreMenuIcon } from "../../utils/SvgIcons";
import {
  getUserData,
  getUserStatus,
  replaceValueSpaceHyphen,
} from "../../utils/Common";
import { HeaderIconBtn } from "../_reusables";
import FloatingMenu from "./FloatingMenu";
import { DataContext } from "../../context";
import BrandWhiteLogo from "../../assets/white-brand-logo.svg";

import Notifications from "./Notifications";
import FlagContainer from "./FlagContainer";
import UserStatus from "./UserStatus";

import { useGetNotifications } from "../../query/NotificationQueries";

import NotificationBanner from "./NotificationBanner";

const status = [
  { text: "Active", color: "#2ed47a" },
  { text: "Away", color: "#ffcd7a" },
  { text: "Do not disturb", color: "#f53a29" },
];

const InnerHeader = ({ isDashboard }) => {
  const fromLocalStatus = getUserStatus();
  const fromLocal = getUserData();
  const [showFloatMenu, setFloatMenu] = useState(false);
  const [showNotifBanner, setNotifBanner] = useState(true);
  const [curStatus, setCurStatus] = useState(
    parseInt(fromLocalStatus) ||
      status.findIndex(
        (item) => item.text.toUpperCase() === fromLocal?.status
      ) ||
      0
  );

  const { userData, companyProfile, initNotifications } =
    useContext(DataContext);

  const history = useHistory();

  const { data, refetch } = useGetNotifications(
    fromLocal?.token,
    fromLocal ? true : false
  );

  const handleShowFloatMenu = () => setFloatMenu(!showFloatMenu);

  useEffect(() => {
    if (fromLocal === null) {
      history.push("/login");
      return;
    }
    if (fromLocal?.token) refetch();
  }, [fromLocal?.token]);

  return (
    <>
      {data &&
        data.notifications.length > 0 &&
        showNotifBanner &&
        fromLocal.next_link &&
        fromLocal.next_link.includes("/dashboard") && (
          <NotificationBanner
            initNotifications={initNotifications}
            setNotifBanner={setNotifBanner}
          />
        )}
      <div className="header-wrapper">
        {/* <div
        className={`inner-header-container ${!isDashboard ? "container" : ""}`}
      > */}
        <div className={`inner-header-container`}>
          <div>
            <div className={`header-branding`}>
              {fromLocal.next_link &&
              !fromLocal.next_link.includes("/docs") &&
              !fromLocal.next_link.includes("/pitch") ? (
                <Link
                  to={`/${replaceValueSpaceHyphen(
                    fromLocal?.first_name
                  )}/dashboard`}
                  style={{ textDecoration: "none" }}
                >
                  <img
                    src={
                      companyProfile
                        ? companyProfile.logo_applicant_white
                        : BrandWhiteLogo
                    }
                    className="headerIco"
                    alt="Satellite Teams"
                  />
                  {/* <img
              src={BrandWhiteLogo}
              className="headerIco"
              alt="Satellite Teams"
            /> */}
                </Link>
              ) : (
                <img
                  src={
                    companyProfile
                      ? companyProfile.logo_applicant_white
                      : BrandWhiteLogo
                  }
                  className="headerIco"
                  alt="Satellite Teams"
                />
              )}
            </div>
          </div>
          <div className="header-user-menu">
            {fromLocal.next_link &&
              fromLocal.next_link.includes("/dashboard") && <Notifications />}
            <Link
              to={`/${replaceValueSpaceHyphen(
                fromLocal?.first_name
              )}/profile/about`}
              style={{ textDecoration: "none" }}
            >
              <div className="user-thumb-btn">
                <span
                  style={{
                    backgroundColor: status[curStatus]?.color || "#2ed47a",
                  }}
                ></span>
                {fromLocal && fromLocal.picture ? (
                  <img src={fromLocal?.picture} alt={fromLocal?.username} />
                ) : (
                  fromLocal && (
                    <span>{fromLocal?.first_name.charAt(0).toUpperCase()}</span>
                  )
                )}
              </div>
            </Link>
            <div className="user-name-role">
              <p>
                Hey,
                {fromLocal && (
                  <span className="user-name">{` ${fromLocal.first_name} ${fromLocal.last_name}`}</span>
                )}
              </p>
              <UserStatus
                status={status}
                curStatus={curStatus}
                setCurStatus={setCurStatus}
              />
            </div>
            <div style={{ position: "relative" }}>
              <HeaderIconBtn
                icon={MoreMenuIcon("#fff")}
                event={handleShowFloatMenu}
              />
              {showFloatMenu && (
                <FloatingMenu handleShowFloatMenu={handleShowFloatMenu} />
              )}
            </div>
            <FlagContainer />
          </div>
        </div>
      </div>
    </>
  );
};
export default InnerHeader;
