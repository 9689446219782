import React, { useContext, useRef, useState } from "react";
import { NavLink, useParams, useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import { useClickAway } from "react-use";
import { Oval } from "react-loader-spinner";

import { DataContext } from "../../context";
import {
  clearSessionStorage,
  getUserData,
  removeUserStatus,
  replaceValueSpaceHyphen,
} from "../../utils/Common";
import "./_FloatingMenu.scss";
import Triangle from "../../assets/floatingTriangle.png";
import { userLogout } from "../../api";

const FloatingMenu = ({ handleShowFloatMenu }) => {
  const [isLogout, setLogout] = useState(false);
  const { username } = useParams();
  const { setUserData, userData } = useContext(DataContext);
  const history = useHistory();

  const fromLocal = getUserData();

  const floatingRef = useRef(null);

  const menu = [
    {
      text: "Profile",
      route: `/${replaceValueSpaceHyphen(fromLocal.first_name)}/profile/about`,
    },
    {
      text: "My Docs",
      route: `/${replaceValueSpaceHyphen(fromLocal.first_name)}/docs`,
    },
    {
      text: "My Pitch",
      route: `/${replaceValueSpaceHyphen(fromLocal.first_name)}/pitch`,
    },
    {
      text: "Portfolio",
      route: `/${replaceValueSpaceHyphen(fromLocal.first_name)}/portfolio`,
    },
    {
      text: "Schedules",
      route: `/${replaceValueSpaceHyphen(fromLocal.first_name)}/schedules`,
    },
    {
      text: "Referrals",
      route: `/${replaceValueSpaceHyphen(fromLocal.first_name)}/referrals/1/10`,
    },
    // { text: "Jobs Applied", route: `/${username}/jobs-applied` },
  ];

  const handleLogout = () => {
    setLogout(true);
    userLogout(userData.token || fromLocal.token)
      .then((res) => {
        if (res.data.success) {
          setLogout(false);
          setUserData({});
          Swal.fire({
            title: "Log Out",
            text: "User successfully log out.",
            // text: res.data.message,
            icon: "info",
            showConfirmButton: true,
          }).then(() => {
            clearSessionStorage();
            removeUserStatus();
            history.push("/login");
          });
        }
      })
      .catch((err) => {
        setLogout(false);
        if (err.response.status === 401) {
          clearSessionStorage();
          removeUserStatus();
          history.push("/login");
        }

        if (err.response.status === 422) {
          Swal.fire({
            title: "Error!",
            text: err.response.data.message,
            icon: "error",
            confirmButtonText: "Close",
            confirmButtonColor: "#55428A",
          });

          return;
        } else {
          console.log(err.response.status);
        }
      });
  };

  const handleMoveToLink = (route) => {
    history.push(route);
    handleShowFloatMenu();
  };

  useClickAway(floatingRef, () => {
    handleShowFloatMenu();
  });

  return (
    <div ref={floatingRef} className="floating-menu-wrapper">
      <div className="floating-menu-container">
        <img src={Triangle} alt="triangle" />
        <div className="menu-container">
          {fromLocal.next_link &&
            !fromLocal.next_link.includes("/docs") &&
            !fromLocal.next_link.includes("/pitch") && (
              <div>
                {menu.map((item, i) => (
                  <div key={i} className="menu-item">
                    <p
                      className="menu-link"
                      onClick={() => handleMoveToLink(item.route)}
                    >
                      {item.text}
                    </p>
                  </div>
                ))}
              </div>
            )}
          <div
            onClick={handleLogout}
            className="menu-item logout-item"
            style={{ borderBottom: "unset", paddingBottom: 0 }}
          >
            {isLogout && (
              <Oval
                color="#7964b5"
                secondaryColor="#d2cbe6"
                strokeWidth={6}
                height={20}
                width={20}
              />
            )}
            <span>Log Out</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FloatingMenu;
