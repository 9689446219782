import React from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

import "./_CustomPhoneInput.scss";

const CustomPhoneInput = ({
  country,
  value,
  label,
  error,
  hasValue,
  event,
}) => {
  return (
    <div
      className={`phone-number-main-container ${
        hasValue ? "phone-has-value" : ""
      } ${error ? "error-phone-border" : ""}`}
    >
      {label && <label className="input-generic-label">{label}</label>}
      <PhoneInput
        country={country}
        enableSearch
        countryCodeEditable={false}
        disableSearchIcon
        containerClass="container-phone-number"
        inputClass="phone-input"
        buttonClass="btn-dropdown"
        dropdownClass="phone-dropdown"
        searchClass="search-field"
        onChange={event}
        value={value && value}
      />
      {error && <p className="error-phone">{error}</p>}
    </div>
  );
};

export default CustomPhoneInput;
