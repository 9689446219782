import React from "react";
import "./_InputGeneric.scss";

const InputGeneric = React.forwardRef(
  (
    {
      type,
      value,
      error,
      name,
      label,
      placeholder,
      isDisable,
      event,
      clickEvt,
      defaultValue,
      readOnly = false,
      height = null,
    },
    ref
  ) => {
    return (
      <div
        className={`input-generic-container ${
          error ? "input-generic-error-border" : ""
        }`}
      >
        {label && <label className="input-generic-label">{label}</label>}
        <input
          type={type}
          name={name}
          defaultValue={defaultValue && defaultValue}
          value={value}
          placeholder={placeholder}
          onChange={event}
          disabled={isDisable}
          style={{
            background: isDisable ? "#f7f7f8" : "#fff",
            height: height || "100%",
          }}
          ref={ref}
          onClick={clickEvt && clickEvt}
          readOnly={readOnly}
        />
        {error && <p className="input-generic-error">{error}</p>}
      </div>
    );
  }
);

export default InputGeneric;
