import React from "react";
import { SmallCheckIcon } from "../../utils/SvgIcons";
import "./_CheckboxCustom.scss";

const CheckboxCustom = ({
  text,
  disabled,
  name,
  value,
  event,
  isActive,
  isCheck,
}) => {
  return (
    <label
      className={`custom-checkbox-container ${
        isActive ? "custom-checkbox-active" : ""
      }`}
    >
      <input
        type="checkbox"
        name={name}
        defaultValue={value ? value : ""}
        disabled={disabled}
        onChange={event}
        // defaultChecked={isCheck}
        checked={isCheck}
      />
      <div>{SmallCheckIcon("#fff")}</div>
      <span>{text}</span>
    </label>
  );
};

export default CheckboxCustom;
