import React, { useState } from "react";
import "./_InputWithIcon.scss";

import { EyeHiddenIcon, EyeVisibleIcon } from "../../utils/SvgIcons";

const InputWithIcon = ({
  type,
  value,
  error,
  name,
  placeholder,
  icon,
  event,
  maxLength,
  contextMenu,
  paste,
  copy,
  label,
}) => {
  const [inputType, setType] = useState(false);

  const handleShowPassword = () => setType(!inputType);

  return (
    <div className="input-with-icon-container">
      {label && <label htmlFor={name}>{label}</label>}
      <div className={error[name] ? "input-with-icon-error" : ""}>
        {icon && icon}
        <input
          id={name}
          type={inputType && type === "password" ? "text" : type}
          name={name}
          defaultValue={value ? value : ""}
          value={value}
          placeholder={placeholder}
          onChange={event}
          maxLength={maxLength ? maxLength : ""}
          required
          onContextMenu={contextMenu}
          onPaste={paste}
          onCopy={copy}
        />

        {type === "password" && (
          <div
            onClick={handleShowPassword}
            style={{ cursor: "pointer", height: 24 }}
          >
            {!inputType ? EyeHiddenIcon("#c5c4c9") : EyeVisibleIcon("#c5c4c9")}
          </div>
        )}
      </div>
      {error[name] && <p className="input-with-icon-error">{error[name]}</p>}
    </div>
  );
};

export default InputWithIcon;
