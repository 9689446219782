import React from "react";

import "./_CustomTextarea.scss";

const CustomTextarea = ({
  label,
  error,
  value,
  rows,
  name,
  placeholder,
  event,
  isDisable,
}) => {
  return (
    <div
      className={`custom-textarea-container ${
        error ? "custom-textarea-error" : ""
      }`}
    >
      {label && <label htmlFor={name}>{label}</label>}
      <textarea
        id={name}
        name={name}
        placeholder={placeholder}
        rows={rows}
        value={value}
        onChange={event}
        disabled={isDisable}
        style={{ background: isDisable ? "#f7f7f8" : "#fff" }}
      ></textarea>
      {error && <p className="custom-textarea-error">{error}</p>}
    </div>
  );
};

export default CustomTextarea;
