import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { CssBaseline, ThemeProvider } from "@mui/material";
import "./index.css";
import "./scss/_variables.scss";
import "./scss/_resets.scss";
import "./scss/_globals.scss";
import App from "./App";
import { theme } from "./config/materialTheme";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { queryClient } from "./config/queryClient";

ReactDOM.render(
  // <React.StrictMode>
  <GoogleOAuthProvider clientId={process.env.REACT_APP_GO_ID}>
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          {/* <CssBaseline /> */}
          <App />
        </BrowserRouter>
      </ThemeProvider>
    </QueryClientProvider>
  </GoogleOAuthProvider>,
  // </React.StrictMode>,
  document.getElementById("root")
);
