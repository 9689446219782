import React from "react";
import { Oval } from "react-loader-spinner";

import "./_ButtonGeneric.scss";

const ButtonGeneric = ({
  text,
  type,
  color,
  hasOutline,
  size,
  disabled,
  loading,
  event,
  style,
}) => {
  return (
    <div className="generic-btn-container" style={style}>
      {loading && (
        <Oval
          color="#fff"
          secondaryColor="#aeadb4"
          height={25}
          width={25}
          strokeWidth={5}
          wrapperClass="generic-btn-spinner"
        />
      )}
      <button
        type={type ? type : "button"}
        onClick={event}
        className={`generic-btn generic-btn-${
          color ? color : "default"
        } generic-btn-size-${size ? size : "default"} ${
          hasOutline ? "generic-btn-outlined" : ""
        } ${loading ? "generic-btn-loading" : ""}`}
        disabled={disabled}
      >
        {text}
      </button>
    </div>
  );
};

export default ButtonGeneric;
