import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import moment from "moment";
import { useQueryClient } from "react-query";

import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";

import { setVisitNotification } from "../../api";
import { getUserData } from "../../utils/Common";
import { DataContext } from "../../context";

import {
  useVisitedNotification,
  queryKeyGetNotifications,
} from "../../query/NotificationQueries";

import "./_NotifCard.scss";

moment.updateLocale("en", {
  relativeTime: {
    future: "in %s",
    past: "%s",
    s: "a few seconds",
    ss: "%d seconds",
    m: "a minute",
    mm: "%d minutes",
    h: "an hour",
    hh: "%d hours",
    d: "a day",
    dd: "%d days",
    w: "a week",
    ww: "%d weeks",
    M: "a month",
    MM: "%d months",
    y: "a year",
    yy: "%d years",
  },
});

const NotifCard = ({ item, setShowOnboard, setNotifications, refetch }) => {
  const history = useHistory();
  const fromLocal = getUserData();
  const { setPhoneVerify } = useContext(DataContext);

  const { mutateAsync, isError, error: err } = useVisitedNotification();
  const queryClient = useQueryClient();

  async function handleClickHere() {
    await mutateAsync({ itemId: item.id, userToken: fromLocal.token });
    refetch();
    if (item.title.toLowerCase().includes("mobile number verification")) {
      setPhoneVerify(true);
      setNotifications(false);
      return;
    }

    if (item.title.includes("Welcome to Satellite Teams!")) {
      // setShowOnboard(true);
      // setNotifications(false);
      return;
    }

    window.open(item.link, "_blank");
    setNotifications(false);
  }

  if (isError) {
    if (err.response.status === 422 || err.response.status === 401) {
      Swal.fire({
        text: err.response.message,
        icon: "warning",
        confirmButtonText: "Ok",
        confirmButtonColor: "#55428A",
      });

      return;
    }

    if (err.response.status === 500) {
      console.log(err.response.status);
    }
  }

  // const handleClickHere = () => {
  //   // history.push(item.link);
  //   setVisitNotification(item.id, fromLocal.token)
  //     .then((res) => {
  //       if (item.title.toLowerCase().includes("mobile number verification")) {
  //         setNotifications(false);
  //         setPhoneVerify(true);

  //         if (item.visit === 0 || item.visit === "0") {
  //           const listInstance = [...notificationList];
  //           const itemInstance = { ...item, visit: 1 };

  //           listInstance.splice(index, 1, itemInstance);

  //           setNotificationList(listInstance);

  //           setNotificationCount((prev) => prev - 1);
  //         }

  //         return;
  //       }

  //       if (
  //         item.action === 1 &&
  //         item.title.includes("Welcome to Satellite Teams!")
  //       ) {
  //         setShowOnboard(true);
  //         setNotifications(false);

  //         if (item.visit === 0 || item.visit === "0") {
  //           const listInstance = [...notificationList];
  //           const itemInstance = { ...item, visit: 1 };

  //           listInstance.splice(index, 1, itemInstance);

  //           setNotificationList(listInstance);

  //           setNotificationCount((prev) => prev - 1);
  //         }

  //         return;
  //       }
  //       window.open(item.link, "_blank");
  //       setNotifications(false);
  //       if (item.visit === 0 || item.visit === "0") {
  //         const listInstance = [...notificationList];
  //         const itemInstance = { ...item, visit: 1 };

  //         listInstance.splice(index, 1, itemInstance);

  //         setNotificationList(listInstance);

  //         setNotificationCount((prev) => prev - 1);
  //       }
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //       if (err.response.status === 422 || err.response.status === 401) {
  //         Swal.fire({
  //           text: err.response.message,
  //           icon: "warning",
  //           confirmButtonText: "Ok",
  //           confirmButtonColor: "#55428A",
  //         });

  //         return;
  //       }

  //       if (err.response.status === 500) {
  //         console.log(err.response.status);
  //       }
  //     });
  // };

  return (
    <div
      className={`notif-card-container ${
        item.visit === 0 ? "new-notif-card" : ""
      }`}
      onClick={handleClickHere}
    >
      <Grid container alignItems="center">
        <Grid item xs={9}>
          <h5>{item.title}</h5>
          <p>{item.highlight}</p>
        </Grid>
        <Grid item xs={3}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent={"flex-end"}
          >
            <Typography
              variant="body1"
              sx={(theme) => ({
                fontSize: ".55rem !important",
                fontWeight: `${600} !important`,
                marginRight: "3px",
              })}
            >
              {moment(item.created_at).fromNow()}
            </Typography>
            <Box
              sx={(theme) => ({
                width: "6px",
                height: "6px",
                borderRadius: "100%",
                backgroundColor:
                  theme.palette.notification[
                    item.visit === 0 ? "unread-dot" : "read-dot"
                  ],
              })}
            />
          </Stack>
        </Grid>
      </Grid>
      {/* {item?.title?.toLowerCase()?.includes("congratulations") ? (
        <p>
          Please <span onClick={handleClickHere}>Click here</span> to view the
          details.
        </p>
      ) : (
        <span onClick={handleClickHere}>Click here</span>
      )} */}
    </div>
  );
};

export default React.memo(NotifCard);
