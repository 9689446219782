import React, { useEffect, useState, useRef } from "react";
import Swal from "sweetalert2";
import Skeleton from "react-loading-skeleton";

import {
  CenterModal,
  HeadingTitle,
  SectionLoader,
  ButtonGeneric,
} from "../_reusables";
import { getOnboardingVideo } from "../../api";
import { getUserData } from "../../utils/Common";
import { CloseIcon } from "../../utils/SvgIcons";
import { agreeOnboarding } from "../../api";
import "./_OnboardingVideo.scss";

const OnboardingVideo = ({ setShowOnboard }) => {
  const [videoFile, setVideoFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const videoRef = useRef(null);
  const [isPlay, setPlay] = useState(false);
  const [isAgree, setAgree] = useState(false);

  const fromLocal = getUserData();

  const handlePlay = () => {
    setPlay(true);
    videoRef.current.play();
  };

  const handleAgree = () => {
    setAgree(true);
    agreeOnboarding(fromLocal.token)
      .then((res) => {
        Swal.fire({
          text: res.data.message,
          icon: "success",
          confirmButtonText: "Ok",
          confirmButtonColor: "#55428A",
        }).then(() => setShowOnboard(false));
      })
      .catch((err) => {
        if (err.response.status === 422 || err.response.status === 401) {
          Swal.fire({
            text: err.response.message,
            icon: "warning",
            confirmButtonText: "Ok",
            confirmButtonColor: "#55428A",
          });

          return;
        }

        if (err.response.status === 500) {
          console.log(err.response.status);
        }
      })
      .finally(() => setAgree(false));
  };

  useEffect(() => {
    const controller = new AbortController();
    setLoading(true);

    getOnboardingVideo(fromLocal.token, controller)
      .then((res) => {
        setVideoFile(res.data.data[0]);
      })
      .catch((err) => {
        if (err.response.status === 422 || err.response.status === 401) {
          Swal.fire({
            text: err.response.message,
            icon: "warning",
            confirmButtonText: "Ok",
            confirmButtonColor: "#55428A",
          });

          return;
        }

        if (err.response.status === 500) {
          console.log(err.response.status);
        }
      })
      .finally(() => setLoading(false));

    return () => controller.abort();
  }, []);

  return (
    <CenterModal>
      {loading ? (
        // <Skeleton width={600} height={350} />
        <div style={{ padding: "0 20px" }}>
          <SectionLoader color1={"#463672"} color2={"#fff"} />
        </div>
      ) : (
        <>
          <HeadingTitle text="Onboarding video" isPrimaryColor>
            <div className="onboarding-heading-cta">
              <button type="button" onClick={() => setShowOnboard(false)}>
                {CloseIcon("#55428a")}
              </button>
            </div>
          </HeadingTitle>

          <div style={{ position: "relative" }}>
            {!isPlay && (
              <div className="demo-play-btn" onClick={handlePlay}>
                Play
              </div>
            )}
            <video
              src={videoFile?.path}
              ref={videoRef}
              type="video/mp4"
              controls={isPlay}
              controlsList="nodownload"
              height={370}
            />
          </div>
          {isPlay && (
            <div
              style={{
                textAlign: "center",
                paddingTop: 40,
                width: 150,
                margin: "0 auto",
              }}
            >
              <ButtonGeneric
                text="Agree"
                size="small"
                color="green"
                loading={isAgree}
                disabled={isAgree}
                event={handleAgree}
              />
            </div>
          )}
        </>
      )}
    </CenterModal>
  );
};

export default OnboardingVideo;
