import React, { useState, lazy, Suspense, useEffect } from "react";
import {
  Switch,
  Route,
  Redirect,
  useLocation,
  useHistory,
} from "react-router-dom";
import _ from "lodash";
import ReactGA from "react-ga4";
import Swal from "sweetalert2";

import { PageLoader } from "./components/_reusables";

import { DataContext } from "./context";
import { getUserData, replaceValueSpaceHyphen } from "./utils/Common";
import { GAPageView4 } from "./utils/GA";
import { DebugMode } from "./utils/DebugMode";
import { getCompanyProfile } from "./api";

import { generateToken, onMessageListener } from "./config/firebase";
import InnerHeader from "./components/Headers/InnerHeader";
import Header from "./components/Headers/Header";

import useDetectDevice from "./utils/useDetectDevice";
import MobileVersionImage from "./assets/mobile-version-image.png";

import "./scss/_globals.scss";

const Login = lazy(() => import("./components/Login/Login"));
const ApplicantRegistration = lazy(() =>
  import("./components/Registration/ApplicantRegistration")
);
const TermsAndCondition = lazy(() =>
  import("./components/Terms/TermsAndCondition")
);
const Questionnaire = lazy(() =>
  import("./components/Questionnaire/Questionnaire")
);
const DocumentUpload = lazy(() =>
  import("./components/DocumentUpload/DocumentUpload")
);
const ForgotPassword = lazy(() => import("./components/Login/ForgotPassword"));
const ResetPassword = lazy(() => import("./components/Login/ResetPassword"));
const ApplicantProfile = lazy(() =>
  import("./components/Profile/ApplicantProfile")
);
const ApplicantPitch = lazy(() => import("./components/Pitch/ApplicantPitch"));
const Portfolio = lazy(() =>
  import("./components/Portfolio/ApplicantPortfolio")
);
const ResumeQuestionnaire = lazy(() =>
  import("./components/ResumeQuestionnaire/ResumeQuestionnaire")
);
const Maintenance = lazy(() => import("./components/Maintenance/Maintenance"));
const ComingSoon = lazy(() => import("./components/ComingSoon/ComingSoon"));
const FourOFour = lazy(() => import("./components/FourOFour/FourOFour"));
const LanguageAssesment = lazy(() =>
  import("./components/LanguageAssesment/LanguageAssesment")
);
const Schedules = lazy(() => import("./components/Scheduler/Schedules"));
const UpdateRegistration = lazy(() =>
  import("./components/Registration/UpdateRegistration")
);
const WelcomePage = lazy(() => import("./components/WelcomePage/WelcomePage"));
const NewDashboard = lazy(() => import("./components/Dashboard/NewDashboard"));

const SingleTerm = lazy(() => import("./components/Terms/SingleTerm"));
const SinglePrivacy = lazy(() => import("./components/Terms/SingelPrivacy"));
const JOpreview = lazy(() => import("./components/JobOffer/JOpreview"));
const Referrals = lazy(() => import("./components/Referrals/Referrals"));
const ReferralPayment = lazy(() =>
  import("./components/Referrals/ReferralPayment")
);

const appFlow = [
  { text: "Fill in your Candidate Profile", index: 1, route: "resume" },
  { text: "Resume uploader", index: 2, route: "docs" },
  { text: "Create your Pitch Video", index: 3, route: "pitch" },
];

function App() {
  const os = useDetectDevice("os");
  const [userData, setUserData] = useState({});
  const [countriesList, setCountriesList] = useState([]);
  const [companyProfile, setCompanyProfile] = useState(null);
  const [currentStep, setCurrentStep] = useState(1);
  const [doneStep, setDoneStep] = useState([]);
  const [isPitchLocation, setPitchLocation] = useState(false);
  const [isLanguageLocation, setLanguageLocation] = useState(false);
  const [tokenNotification, setTokenNotification] = useState(null);
  const [joModal, setJoModal] = useState(false);
  const [isPhoneVerify, setPhoneVerify] = useState(false);
  const [activityPhoneCompleted, setActivityPhoneCompleted] = useState(0);
  const [isEnglishAssComplete, setEnglishAssComplete] = useState(false);
  // init data for notification banner and panel, check header notification for setter
  const [initNotifications, setInitNotifications] = useState([]);

  const history = useHistory();

  const { pathname } = useLocation();

  const fromLocal = getUserData();

  ReactGA.initialize(process.env.REACT_APP_GA);

  generateToken(setTokenNotification);

  useEffect(() => {
    if (
      os?.name === "iPhone" ||
      os?.name === "iPad" ||
      os?.name === "Android"
    ) {
      Swal.fire({
        html: `<p>Hello! We recommend using your computer browser for accessing the Satellite Teams Careers Website.</p>
        <p>For better user experience and your convenience.</p>`,
        iconHtml: `<img src=${MobileVersionImage} alt="ipad" />`,
        customClass: {
          htmlContainer: "swal-custom-text",
          icon: "swal-custom-icon-2",
        },
        allowOutsideClick: false,
        // toast: true,
        // position: "bottom-start",
        background: "#f0eef7",
        confirmButtonText: "Continue using mobile browser",
        confirmButtonColor: "#55428a",
      });
    }
  }, [os]);

  useEffect(() => {
    const controller = new AbortController();

    getCompanyProfile(controller).then((res) => {
      if (res.data.success) {
        const favicon = document.getElementById("my-favicon");
        favicon.setAttribute("href", res.data.data.favicon);
        setCompanyProfile(res.data.data);
      }
    });

    return () => {
      controller.abort();
    };
  }, []);

  useEffect(() => {
    GAPageView4(window.location.pathname, "pageview");
    // ReactGA.send({ hitType: "pageview", page: "yes" });
    const indOfPath = pathname.lastIndexOf("/") + 1;
    const section = pathname.slice(indOfPath);
    let doneArr = [];

    if (process.env.REACT_APP_PROD_DEBUG === "1") {
      DebugMode(false, true);
    }

    if (fromLocal !== null) {
      if (!_.isEmpty(fromLocal.next_link)) {
        if (
          fromLocal.next_link.includes("dashboard") ||
          fromLocal.next_link.includes("profile")
        ) {
          //update later when questionnaire is added
          setCurrentStep(4);
          setDoneStep([1, 2, 3]);
          return;
        }
      }

      for (let i = 0; i < appFlow.length; i++) {
        const { route, index } = appFlow[i];

        if (route === section) {
          setCurrentStep(index);
          setDoneStep(doneArr);
          break;
        }

        doneArr.push(index);
      }
    }
  }, [pathname]);

  return (
    <DataContext.Provider
      value={{
        userData,
        setUserData,
        currentStep,
        setCurrentStep,
        doneStep,
        setDoneStep,
        appFlow,
        countriesList,
        setCountriesList,
        companyProfile,
        setPitchLocation,
        isPitchLocation,
        isLanguageLocation,
        setLanguageLocation,
        setTokenNotification,
        tokenNotification,
        joModal,
        setJoModal,
        setInitNotifications,
        initNotifications,
        setPhoneVerify,
        isPhoneVerify,
        isEnglishAssComplete,
        setEnglishAssComplete,
        setActivityPhoneCompleted,
        activityPhoneCompleted,
      }}
    >
      <div className="App">
        {/* {joModal && <JOpreview />} */}
        {fromLocal ? <InnerHeader /> : <Header />}
        <Suspense
          fallback={
            <PageLoader
              color1={"#463672"}
              color2={"#fff"}
              containerHeight="100vh"
            />
          }
        >
          <Switch>
            <Route exact path="/">
              <Redirect to="/login" />
            </Route>
            <Route exact path="/login" component={Login} />
            <Route path="/register" component={ApplicantRegistration} />
            <Route path="/update-credentials" component={UpdateRegistration} />
            {/* <Route path="/terms-and-condition" component={TermsAndCondition} /> */}
            <Route path="/forgot-password" component={ForgotPassword} />
            <Route path="/reset-password" component={ResetPassword} />
            <Route path="/:username/resume" component={ResumeQuestionnaire} />
            <Route path="/:username/questionnaire" component={Questionnaire} />
            <Route path="/:username/docs" component={DocumentUpload} />
            <Route
              path="/:username/profile/:section"
              component={ApplicantProfile}
            />
            <Route path="/:username/pitch" component={ApplicantPitch} />
            <Route path="/:username/portfolio" component={Portfolio} />
            {/* <Route path="/:username/dashboard" component={Dashboard} /> */}
            <Route path="/:username/dashboard" component={NewDashboard} />
            <Route path="/:username/newdashboard" component={NewDashboard} />
            {process.env.REACT_APP_SHOW_LANGUAGE_ASSESSMENT === "1" && (
              <Route
                path="/:username/language-assessment"
                component={LanguageAssesment}
              />
            )}
            <Route
              path="/:username/referrals/:page/:entries"
              component={Referrals}
            />
            <Route
              path="/:username/referrals/payment"
              exact
              component={ReferralPayment}
            />

            <Route path="/:username/schedules" component={Schedules} />
            <Route path="/:username/Welcome" component={WelcomePage} />
            {/* <Route path="/:username/jobs-applied" component={JobsApplied} /> */}
            <Route path="/maintenance" component={Maintenance} />
            <Route path="/coming-soon" component={ComingSoon} />
            <Route path="/:username/job-offer/:id" component={JOpreview} />

            <Route path="/terms" component={SingleTerm} />
            <Route path="/data-privacy" component={SinglePrivacy} />

            <Route path="*">
              <FourOFour />
            </Route>
          </Switch>
        </Suspense>
      </div>
      <p
        style={{
          position: "fixed",
          bottom: 5,
          right: 5,
          fontSize: ".7rem",
          color: "#ccc",
        }}
      >
        {"v. " + process.env.REACT_APP_VERSION}
      </p>
    </DataContext.Provider>
  );
}

export default App;
