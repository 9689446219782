import React from "react";
import "./_CustomRadio.scss";

const CustomRadio = ({
  text,
  id,
  name,
  value,
  event,
  isChecked,
  isDisabled = false,
}) => {
  return (
    <label className="custom-radio-container" htmlFor={id}>
      <input
        type="radio"
        name={name}
        defaultValue={value}
        id={id}
        onChange={event}
        checked={isChecked}
        disabled={isDisabled}
      />
      <div className={isChecked ? "radio-isChecked" : ""}></div>
      <span>{text}</span>
    </label>
  );
};

export default CustomRadio;
