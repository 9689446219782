// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "* {\n  margin: 0;\n  padding: 0;\n  border: 0;\n  box-sizing: border-box;\n}\n\ntextarea:focus,\ninput:focus {\n  outline: none;\n}\n\nimg {\n  max-width: 100%;\n}\n\nvideo {\n  width: 100%;\n  border-radius: 5px;\n}\n\nul {\n  list-style: none;\n}", "",{"version":3,"sources":["webpack://./src/scss/_resets.scss"],"names":[],"mappings":"AAEA;EACE,SAAA;EACA,UAAA;EACA,SAAA;EACA,sBAAA;AAAF;;AAGA;;EAEE,aAAA;AAAF;;AAGA;EACE,eAAA;AAAF;;AAGA;EACE,WAAA;EACA,kBAAA;AAAF;;AAGA;EACE,gBAAA;AAAF","sourcesContent":["@import url(\"https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap\");\n\n* {\n  margin: 0;\n  padding: 0;\n  border: 0;\n  box-sizing: border-box;\n}\n\ntextarea:focus,\ninput:focus {\n  outline: none;\n}\n\nimg {\n  max-width: 100%;\n}\n\nvideo {\n  width: 100%;\n  border-radius: 5px;\n}\n\nul {\n  list-style: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
