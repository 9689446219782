import React from "react";

const CenterModal = ({ children, fixedHeight = false }) => {
  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        height: "100vh",
        width: "100%",
        background: "rgba(0,0,0,0.5)",
        zIndex: 20,
        padding: 20,
      }}
    >
      <div
        style={{
          background: "#fff",
          borderRadius: 10,
          maxWidth: 1000,
          width: "fit-content",
          margin: "20px auto",
          height: fixedHeight ? "80vh" : "fit-content",
          padding: 20,
          position: "absolute",
          left: "50%",
          top: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        {children}
      </div>
    </div>
  );
};

export default CenterModal;
