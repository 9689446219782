import React, { useState, useEffect, useRef } from "react";
import CurrencyInput, { formatValue } from "react-currency-input-field";
import { useClickAway } from "react-use";
import searchCountryIcon from "../../assets/searchCountry.png";

import { caretDownOutline } from "../../utils/SvgIcons";

import "./_CustomCurrencyInput.scss";
import "../../scss/_customScrollbar.scss";

const defaultOptions = [
  {
    // locale: 'de-DE',
    currency: "EUR",
  },
  {
    // locale: 'en-US',
    currency: "USD",
  },
  {
    // locale: 'en-GB',
    currency: "GBP",
  },
  {
    // locale: 'ja-JP',
    currency: "JPY",
  },
  {
    // locale: 'en-IN',
    currency: "INR",
  },
  {
    // locale: "en-PH",
    currency: "PHP",
  },
];

const localeConfig = {
  MXN: "es-MX",
  MYR: "ms-MY",
  BND: "ms-BN",
  HKD: "en-HK",
};

const CustomCurrencyInput = ({
  label,
  error,
  name,
  placeholder,
  listCurrency,
  event,
  defaultValue,
  defaultCurrency,
  setFormUpdate,
  isDisabled = false,
  value,
}) => {
  const [config, setConfig] = useState();
  const [options, setOptions] = useState();
  const [isOpen, setOpen] = useState(false);
  const initOptionsRef = useRef([]);
  const dropRef = useRef(null);

  const handleSelectCurrency = (i) => {
    const newOption = {
      ...options[i],
      locale: localeConfig[options[i].currency],
    };
    // setConfig(
    //   options[i]?.currency === "MXN"
    //     ? { ...options[i], locale: "es-MX" }
    //     : options[i]
    // );
    setConfig(newOption);
    setOpen(false);
    setOptions(initOptionsRef.current);
    setFormUpdate(options[i]);
  };

  const handleShowDrop = () => {
    if (!isOpen) setOpen(true);
  };

  const handleHideDrop = () => {
    if (isOpen) setOpen(false);
  };

  const handleSearch = (e) => {
    const value = e.target.value;
    // const pattern = /^[a-zA-Z\s]+$/;

    // if (pattern.test(value)) {
    //   e.target.value = "";
    //   return;
    // }

    let formatedValue = value || "";

    if (value.length === 0) {
      setOptions(initOptionsRef.current);
      return;
    }

    const searchResult = initOptionsRef.current.filter((item) => {
      if (item.country.toLowerCase().includes(formatedValue.toLowerCase())) {
        return item;
      }

      if (
        item.country_abrv.toLowerCase().includes(formatedValue.toLowerCase())
      ) {
        return item;
      }

      if (item.currency !== null) {
        if (item.currency.toLowerCase().includes(formatedValue.toLowerCase())) {
          return item;
        }
      }

      return false;
    });

    setOptions(searchResult);
  };

  const handleFormatValue = (value) => {
    const formated = formatValue({
      value,
      intlConfig: { currency: config.currency },
    });

    event(formated, config);
  };

  const handleValueChange = (value) => {
    event(value, config);
  };

  useClickAway(dropRef, handleHideDrop);

  useEffect(() => {
    if (listCurrency) {
      const defaultCur = defaultCurrency
        ? listCurrency.filter((item) => item.currency === defaultCurrency)
        : listCurrency.filter((item) => item.default === 1);
      // const mexicoWithLocale = { ...defaultCur[0], locale: "es-MX" };
      const newOption = {
        ...defaultCur[0],
        locale: localeConfig[defaultCur[0]?.currency],
      };
      setConfig(newOption || defaultOptions[1]);
      // setConfig(
      //   defaultCur[0]?.currency === "MXN"
      //     ? mexicoWithLocale
      //     : defaultCur[0] || defaultOptions[1]
      // );
      setOptions(listCurrency || defaultOptions);
      initOptionsRef.current = listCurrency;
    }
  }, [listCurrency, defaultCurrency]);

  return (
    <div className="currency-input-container">
      {label && <label htmlFor={name}>{label}</label>}
      <div
        className={error ? "currency-input-error" : ""}
        style={{ background: isDisabled ? "#cfd5de" : "#fff" }}
      >
        <div
          className="custom-select-currency"
          style={{ background: isDisabled ? "#cfd5de" : "#fff" }}
        >
          <select
            className="flag-dummy-select"
            onFocus={handleShowDrop}
          ></select>
          <div
            className="currency-selected"
            onClick={name !== "total" ? handleShowDrop : null}
          >
            {config && (
              <>
                <img src={config.flag} alt={config.currency} />
                <span>{config.currency}</span>
                {name !== "total" && caretDownOutline("#cfd5de")}
              </>
            )}
          </div>
          {isOpen && (
            <div className="currency-options custom-scrollbar" ref={dropRef}>
              <div className="country-search-container">
                <img src={searchCountryIcon} alt="search" />
                <input
                  type="text"
                  placeholder="Search currency"
                  onInput={handleSearch}
                />
              </div>
              <div>
                {options &&
                  options.map((item, i) => (
                    <div
                      className="currency-option"
                      key={i}
                      onClick={() => handleSelectCurrency(i)}
                    >
                      <img src={item.flag} alt={item.currency} />
                      <span>{item.currency}</span>
                    </div>
                  ))}
              </div>
            </div>
          )}
        </div>
        <CurrencyInput
          style={{ background: isDisabled ? "#cfd5de" : "#fff" }}
          intlConfig={config}
          className="currency-input"
          placeholder={placeholder}
          decimalsLimit={6}
          onValueChange={handleValueChange}
          allowNegativeValue={false}
          defaultValue={defaultValue}
          prefix={" "}
          disabled={isDisabled}
          value={value && value}
        />
      </div>
      {error && <p className="currency-input-error-notif">{error}</p>}
    </div>
  );
};

export default CustomCurrencyInput;
