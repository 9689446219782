import ReactGA from "react-ga4";

export const GAPageView4 = (page, hitType) => {
  return ReactGA.send({ hitType, page });
  //   return ReactGA.send(page);
};

export const GAEvent4 = (category, action, label) => {
  return ReactGA.event({ category, action, label });
};
